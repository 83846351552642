@use './../../themes/variables.scss' as vars;

.survey-container {
    padding: 20px 16px 60px;
}

@media (min-width: 960px) {
    .survey-container {
        padding: 20px 16px 65px 16px;
    }
}

.survey-detail-items {
    padding: 16px !important;
    margin-bottom: 16px;
}

@media (max-width: 425px) {
    .survey-detail-info {
      color: #00000080;
      font-size: 13px !important;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 20ch;
    }
    .survey-detail-heading {
      font-size: 14px !important;
      width: max-content;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 35vw !important;
    }
    .survey-detail-items {
      padding: 15px 10px !important;
    }
    .survey {
      margin-bottom: 0dvb;
    }
    .survey-time {
        color: #00000080 ;
        font-size: 13px !important;
        font-weight: 400;
    }
    .heading-text {
        font-size: 16px !important;
    }
    .ok-btn {
        font-size: 12px !important;
    }
    .modal-content {
        padding: 0px !important;
        margin-top: 140px !important;
    }
}

.survey {
    display: flex;
    align-items: center;
    > a {
        max-width: inherit;
        min-width: 70px;
        text-align: center;
        background-color: vars.$greyish-color;
        padding: 8px 0;
        font-size: 12px;
        border-radius: 5px;
        text-decoration: none !important;
    }
}

.survey-text p {
    margin: 0;
}

.survey-detail-profile-pic {
    padding: 8px 12px 8px 12px;
    background: #8080802b;
    font-weight: 600;
    text-align: center;
    margin: auto;
    max-width: 40px;
    border-radius: 2px;
}

.count-data {
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-survey {
    text-align: center;
    padding: 0px 0px 0px  7px;
}

.survey-text{
    font-size: 13px !important;
    font-weight: 400;
    justify-content: space-between;
    display: flex;
    color: #000;
    margin: 0;
    margin-left: 20px;
    width: 100%;
}

.survey-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.survey-detail-heading {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60vw;
    &:hover {
        color: #0C3450;
        text-decoration: underline;
        font-weight: 500;
    }
}

  .survey-detail-info {
    color: #00000080;
    font-size: 13px !important;
}

.survey-time {
    color: #00000080 ;
    font-size: 13px;
    font-weight: 400;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.survey-time:hover {
    animation-iteration-count: initial;
}


.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.modal-content {
    padding: 20px;
    width: 30%;
    margin-top: 15%;
    margin-left: 38%;
}

@media (max-width: 1024px) {
    .modal-content {
        width: 45%;
        margin-left: 25%;
    }
}

.heading {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    background-color:#fff;
    padding:20px 40px;
    text-transform: none;
}

.heading-text {
    font-weight:normal;
    color: black;
    font-size: 24px;
    text-align: center;
}

.ok-btn {
    font-weight: 500;
    padding: 10px 30px;
    font-size: 15px;
    margin: 15px;
    background-color: #0C3450;
    color: white;
    cursor: pointer;
}

.ok-align {
    display: flex;
    flex-direction: row;
}

.inner-search-box {
    border-radius: 0px !important;
    @media (max-width: 767px) {
        border-radius: 50px !important;
        & div {
            & fieldset {
                border: none;
            }
        }
    }
}
